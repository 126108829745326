<template>
  <div>
    <!--    pc-->
    <div class="flex gap-[80px] mobile:!hidden pt-[20px] pb-[150px] min-w-[1200px]">
      <div class="filter-wrap  w-[200px]">
        <div class="flex justify-content-between py-[18px] items-center justify-center">
          <h2 class="text-[24px] font-[Suit-Bold] leading-[44px]">Filter</h2>
          <div class="cursor-pointer w-[20px] h-[20px] bg-no-repeat bg-center" @click="resetFilter" :style="{backgroundImage: `url('${require('@/assets/images/icon/filter-reload.png')}')`}"/>
        </div>
        <div class="h-[1px] w-full bg-light" />

        <PcGroupCheckbox
            :list="statusList"
            title="Status"
            :is-open="isOpenStatusFilter"
            :change-filter="changStatus"
        />

        <PcGroupCheckbox
            v-for="item in filterList"
            :key="item.name"
            :list="item.list"
            :title="item.name"
            :is-open="item.isOpen"
            :change-filter="changeFilter"
        />
      </div>
      <div class="flex flex-col gap-[40px] items-end w-full">
        <ul class="flex">
          <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 1 ? '#A8221A' : '#424242', fontFamily: sortFilter === 1 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(1)">최신순</li>
          <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 2 ? '#A8221A' : '#424242', fontFamily: sortFilter === 2 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(2)">가격 높은 순</li>
          <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 3 ? '#A8221A' : '#424242', fontFamily: sortFilter === 3 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(3)">가격 낮은 순</li>
        </ul>

        <div class="pc-wine-list w-full">
          <PcWineList :wine-list="viewWineList" :page-info="pageInfo" :move-page="getWineList"
                      :is-redeem="isRedeem"/>
        </div>
      </div>
    </div>

    <!--  mobile-->
    <div class="hidden mobile:!flex flex-col px-[16px] gap-[16px]">
      <div class="flex pt-[26px] justify-between">
        <div class="bg-[center_left] pl-[32px] bg-no-repeat bg-[length_24px] text-[16px] !font-[Suit-Bold] text-darkDark leading-[32px]"
            :style="{backgroundImage: `url('${require('@/assets/images/icon/mobile-filter.png')}')`}"
             @click="filterModalIsOpen = true"
        >Filter</div>
        <div class="py-[6px] text-[16px] text-[#A8221A] font-[Suit-Bold]" @click="isOpenSortFilter=true">{{ getSort() }}</div>
      </div>
      <div v-if="$route.query.sub_category_no" class="flex flex-col gap-[16px] w-full">
        <div class="w-full h-[1px] bg-light"/>
        <div class="flex gap-[10px] relative">

          <div class="flex w-full overflow-auto gap-[8px] flex-grow pr-[30px]">
            <div
                v-for="(filter, index) in selectFilterList"
                :key="filter.name"
                class="flex w-fit flex-shrink-0 items-center flex-[0 0 auto] border border-lightLightDark rounded-[16px] h-[32px] pl-[16px] pr-[6px]"
                @click="removeFilter(filter, index)"
            >
              <p class=" text-[14px] font-[Suit-Medium] text-darkLight m-0 flex-shrink-0">{{filter.mainName}}: {{filter.name}}</p>
              <img src="@/assets/images/icon/icon-dark-dark-close.png" class="w-[32px] h-[32px] flex-shrink-0" />
            </div>
          </div>
          <div class="cursor-pointer w-[32px] h-[32px] bg-no-repeat bg-center" @click="resetFilter" :style="{backgroundImage: `url('${require('@/assets/images/icon/filter-reload.png')}')`}"/>
          <div class="w-[40px] h-[32px] top-0 bottom-0 bg-gradient-to-r from-[#FFFFFF00] to-white absolute right-[39px]" />

        </div>

      </div>
      <div class="mobile-wine-list">
        <MobileWineList
            :wine-list="viewWineList"
            :next-page="nextPage"
            :is-redeem="isRedeem">

        </MobileWineList>
      </div>
    </div>


    <FilterModal
        v-if="filterModalIsOpen"
        :originFilterList="filterList"
        :close-filter-modal="changeFilter"
        :is-mypage="true"
        @close="filterModalIsOpen=false"
        @change="mobileFilterChange"
        :status="statusList"
    />

    <NftSortModal :order="sortFilter" v-if="isOpenSortFilter" @change="changeOrder" />
  </div>
</template>
<script>
import PcWineList from "../../../components/list/PcWineList";
import MobileWineList from "../../../components/list/MobileWineList";
import getContractApi from "../../../api/contract";
import FilterModal from '../../../components/modals/FilterModal.vue';
import PcGroupCheckbox from "@/components/checkbox/pcGroupCheckbox";
import NftSortModal from "@/components/modals/NftSortModal";
import nftList from "@/mxins/nftList";

export default {
  components: {NftSortModal, PcGroupCheckbox, MobileWineList, PcWineList, FilterModal},
  mixins: [nftList],
  created() {
    this.market = getContractApi();

  },
  data() {
    return {
      market: null,
      viewWineList: [],
      filteredWineList: [],
      pageInfo: {
        current_page: 1,
        total_page: 1,
        item_per_page: 1
      },
      contract: undefined,
      isRedeem: false,
      sellIng: false,
      // state: "01",
      searchData: {},

      isOpenStatusFilter: true,
      filterList: [],

      statusList: [
        {
          key: '01',
          name: 'Listed',
          select: false,
        },
        {
          key: '02',
          name: 'Unlisted',
          select: false,
        },
        {
          key: '03',
          name: 'Redeemed',
          select: false,
        },
      ],


      selectFilterList: [],
      filterModalIsOpen: false,
    }
  },
  mounted() {
    let self = this
    this.searchData = this.$route.query

    /*if (this.searchData.mypage) {
      this.state = this.searchData.mypage
      if(this.state === '03') {
        this.isRedeem = true
      }
    }*/
    this.market.getWineMarket().getUserAddress().then(data => {
      if(data === undefined) {
        alert('메타마스크 연결을 확인해 주세요.')
        location.href='/'
      }
      else {
        self.searchData.owner = data
        self.$store.state.user.walletAddress = data;

        if("mypage" in this.$route.query){
          const selectStatus = this.$route.query.mypage.split(',');
          selectStatus.forEach(select => {
            self.statusList.forEach(status => {
              if(status.key == select){
                status.select = true;
                status.mainName = 'Status'

                this.selectFilterList.push(status);
              }
            })
          })
        }else{
          self.statusList.forEach((state) => {
            if(state.key == '01' || state.key == '02'){
              state.select = true;
              state.mainName = 'Status'

              self.selectFilterList.push(state);
            }
          })
        }


        if (self.searchData.page) {
          self.getWineList(self.searchData.page, 'pass')

        } else {
          self.getWineList(1, 'pass')
        }


        this.$store.dispatch('wineFilterList/getWineFilterList', {'is_mypage': 1})
            .then((data) => {

              // console.dir(data)

              this.filterList = data;
              if(this.$route.query.sub_category_no) {
                let subCategoryList = this.$route.query.sub_category_no.split(',')
                this.filterList.forEach(main => {
                  main.list.forEach(sub => {
                    if(subCategoryList.find(item => item === sub.key.toString())) {
                      sub.select = true
                      main.isOpen = true
                      sub.mainName = main.name;
                      this.selectFilterList.push(sub);
                    }

                  })
                })
              }
            });

      }

    })

  },
  methods: {
    changStatus(){
      const selectStatus = [];
      this.statusList.forEach((status) => {
        if(status.select){
          selectStatus.push(status.key)
        }
      });

      this.state = selectStatus.join(',');
      this.getWineList(1, 'pass')
    },
    getWineList(page, mode, state) {
      // 통신 처리
      /*if (page !== this.pageInfo.current_page) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }*/

      let self = this
      this.searchData.page = page
      // this.searchData.mypage =
      if (mode === "pass") {
        self.$router.push({
          path: this.$route.path,
          query: {
            'mypage': this.statusList.map((state) => state.select ? state.key : null).filter(item => item!=null).join(','),
            'sub_category_no': this.getFilterSelect(),
            'page': page,
            'order': this.sortFilter
          }
        }).catch((error) => {
          console.dir(error)
          // self.$router.go(0)
        });
        // location.href = '/users/mypage?' + new URLSearchParams(this.searchData).toString()
      }

      this.$store.dispatch('wineMarket/getWineMarketList', this.searchData).then((data) => {
        // 데이터 재가공
        // console.dir(data.data)
        let searchedWineList = data.data
        for (let i = 0; i < searchedWineList.length; i++) {
          searchedWineList[i].name = searchedWineList[i].wine_name
          searchedWineList[i]['price'] = {
            coin: searchedWineList[i].last_price / 1000000,
            won: self.market.toWon(searchedWineList[i].last_price / 1000000)
          }
          searchedWineList[i].key = searchedWineList[i].market_key + "/" + searchedWineList[i].nft_key
          searchedWineList[i].image = searchedWineList[i].wine_image
        }

        if (mode === 'mobile') {
          self.viewWineList.push(...searchedWineList)
          state.loaded();
        } else {
          self.viewWineList = searchedWineList
        }


        self.pageInfo = {
          current_page: data.current_page,
          total_page: data.last_page,
          item_per_page: data.per_page,
        }

      })

    },
    nextPage($state) {
      if (this.pageInfo.current_page < this.pageInfo.total_page) {
        this.getWineList(this.pageInfo.current_page + 1, 'mobile', $state)
        // $state.loaded(); // 다음 페이지가 있을 경우
      } else {
        $state.complete(); // 다음 페이지가 없을 경우
        // alert('마지막 페이지 입니다.');
      }
    },
    getWineFilterList() {
      this.$store
          .dispatch('wineFilterList/getWineFilterList', {})
          .then((data) => {
            this.filterList = data;
            this.getWineList(1,"pass")
          });
    },


    changeFilter() {
      this.filterModalIsOpen = false;
      /*this.filterSelected = ''
      this.filterList.forEach(item => {
        item.list.forEach(listItem => {
          if(listItem.select) {
            this.filterSelected = this.filterSelected + listItem.key + ','
          }
        })
      })

      this.searchData.sub_category_no = this.filterSelected*/


      this.getWineList(1,"pass")
    },
    mobileFilterChange(data){
      this.filterList = data.filter;
      this.statusList = data.status;

      this.filterModalIsOpen = false;
      this.getWineList(1,"pass")
    },

    getFilterSelect(){
      const select = [];

      this.filterList.forEach(item => {
        item.list.forEach(listItem => {
          if(listItem.select) {
            select.push(listItem.key);
          }
        })
      })

      return select.join(',')
    },
    removeFilter(item, index){
      // console.dir(item)
      if(item.mainName === 'Status'){
        this.statusList.forEach((state) => {
          if(state.key === item.key){
            state.select = false;
          }
        })
      }else{
        this.filterList.forEach((filter) => {
          if(filter.name == item.mainName){
            filter.list.forEach((i) => {
              if(i.key === item.key){
                i.select = false;
              }
            })
          }
        })
      }


      this.selectFilterList.splice(index, 1);

      this.getWineList(1, 'pass');
    }

  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/mypage.scss";
</style>
